
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  components: {
    VueSlickCarousel: () => import("vue-slick-carousel"),
  },
  props: {
    member_name: {
      type: String,
    },
    samePage: {
      type: Boolean,
      default: true,
    },
    tabData: {
      type: Array,
      default: () => [
        {
          name: "Item",
          onClick: {
            url: "/",
            sameDomain: true,
          },
          activeCondition: "index === activeTabIndex",
        },
      ],
    },
    carouselSettings: {
      type: Object,
      /* 
            <----- Carousel Settings Example ----->

            {
                arrows: false,                      *Navigation Arrow
                dots: false,                        *Navigation Dots
                infinite: false,                    *Infinite Sliding
                slidesToShow: 3,                    *Item to show per Slide
                slidesToScroll: 3                   *Item scrolled per Scrolls,
                variableWidth: true                 *Item fit to content (not using slidesToShow)
                responsive: [                       *Responsive settings
                    {
                        breakpoint: 720,            *Screen breakpoint by Max Width
                        ...carouselSettingsOn720
                    }
                ]
            }
        */
    },
  },
  data: () => ({
    activeTabIndex: 0,
    isMouseDown: false,
    initialPos: 0,
  }),
  methods: {
    handleSelect(tabItem, index) {
      this.activeTabIndex = index;
      if (tabItem.onClick)
        if (tabItem.onClick.sameDomain) this.$router.push(tabItem.onClick.url);
        else window.open(tabItem.onClick.url, "_self");

      this.$emit("contentChange", this.activeTabIndex);
    },
  },
};
