
export default {
  props: {
    discussionData: {
      default: {
        description: "",
        created_at: "",
        description: "",
        total_comment: 0,
        total_like: 0,
        total_view: 0,
        community: {
          community_name: "",
        },
        listing: {},
        user: {
          avatar: "",
          username: "",
          level: "",
        },
      },
    },
  },
  created() {
    this.listingurl = process.env.BASE_LISTING;
  },
  mounted() {
    if (this.discussionData.image_path)
      this.medias = [
        {
          url: this.discussionData.image_path,
        },
      ];
  },
  data() {
    return {
      listingData: {
        marketplace_item: {
          title: "tes listing",
          price: 60000,
          city: { city_name: "Jakarta" },
        },
        communities_content: {
          image_path: "/mb/img/listing-img.png",
        },
      },
      medias: [],
      listingUrl: "",
    };
  },

  methods: {
    handleDetail() {
      if (this.discussionData.listing) {
        window.open(
          `${process.env.BASE_COMMUNITY}mbls/detail/${this.discussionData.community.id}/${this.discussionData.listing.id}`,
          "_self"
        );
      } else {
        this.$router.push(
          `/community/${this.discussionData.community.id}/${this.discussionData.id}`
        );
      }
    },
  },
};
