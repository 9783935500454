
export default {
  props: ["member_name"],
  data: () => ({
    activeTabIndex: 0,
    isMouseDown: false,
    initialPos: 0,
    tabData: [],
  }),
  methods: {
    handleSelect(val) {
      this.activeTabIndex = val;
      this.$emit("contentChange", this.activeTabIndex);
    },
    mouseMoveEventHandler(e) {
      if (this.isMouseDown) {
        if (this.initialPos < e.pageX) {
          this.page = 0;
        } else {
          this.page = 1;
        }
      }
    },
  },
  mounted() {
    this.tabData = [
      "All Post",
      this.member_name ?? "Member",
      "Media",
      "Listing",
    ];
    const myRef = this.$refs.sliderContainer;
    myRef.addEventListener("mousedown", (e) => {
      this.isMouseDown = true;
      this.initialPos = e.pageX;
    });
    myRef.addEventListener("mouseup", () => {
      this.isMouseDown = false;
    });
    myRef.addEventListener("mouseout", () => {
      this.isMouseDown = false;
    });
    myRef.addEventListener("mousemove", this.mouseMoveEventHandler);
  },
};
