
export default {
  components: {
    questionGallery: () => import("@/components/organisms/gallery.vue"),
  },
  props: ["medias", "listingData", "communityData", "isLogin"],
  data() {
    return {};
  },
  methods: {
    handleDetail() {
      window.open(
        `${process.env.BASE_COMMUNITY}mbls/detail/${this.communityData.id}/${this.listingData.communities_content_id}`,
        "_self"
      );
    },
  },
};
